import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Text from './fields/text';
import Textarea from './fields/textarea';

import Select from './fields/select';
import Check from './fields/check';

import {
    Col, Row, Container
} from 'reactstrap';


const required = value => value ? undefined : "Required"

const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children,
}) => (

    <Select
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
        children={children}
    />
)



const renderTextField = ({
    input,
    placeholder,
    label,
    type,
    meta: { touched, error },
}) => (

    <Text
        placeholder={placeholder}
        label={label}
        type={type}
        errorText={touched && error}
        error={touched && error}

        {...input}
    />
)

const renderCheckField = ({
    input,
    placeholder,
    label,
    type,
    meta: { touched, error },
}) => (

    <Check
        placeholder={placeholder}
        label={label}
        type={type}
        errorText={touched && error}
        error={touched && error}

        {...input}
    />
)

const renderTextareaField = ({
    input,
    placeholder,
    label,
    type,
    meta: { touched, error },
}) => (

    <Textarea
        placeholder={placeholder}
        label={label}
        type={type}
        errorText={touched && error}
        error={touched && error}

        {...input}
    />
)

const countriesMapDe = ['Österreich', 'Litauen', 'Belgien', 'Luxemburg', 'Bulgarien', 'Ungarn', /*'Zypern', 'Malta',*/ 'Tschechien', 'Deutschland', 'Dänemark', 'Niederlande', 'Estland', 'Polen', 'Finnland', 'Portugal', 'Frankreich', 'Rumänien', 'Griechenland', 'Slowakei', 'Kroatien', 'Slowenien', 'Irland', 'Spanien', 'Italien ', 'Schweden', 'Lettland']
var orderForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    console.log(pristine, submitting);

    return (
        <form onSubmit={handleSubmit}>

            <Row>
                <Col lg="12">
                    <Field
                        name="Name"
                        type="text"
                        component={renderTextField}
                        label={props.translate('Ime i prezime *')}
                        placeholder=""
                        validate={required}

                    ></Field>

                    <Field
                        name="Street"
                        type="text"
                        component={renderTextField}
                        label={props.translate('Ulica *')}
                        placeholder=""
                        validate={required}

                    ></Field>

                    <Row>
                        <Col lg="6">
                            <Field
                                name="Zipcode"
                                type="text"
                                component={renderTextField}
                                label={props.translate('Poštanski broj *')}
                                placeholder=""
                                validate={required}

                            ></Field>
                        </Col>
                        <Col lg="6">
                            <Field
                                name="City"
                                type="text"
                                component={renderTextField}
                                label={props.translate('Grad *')}
                                placeholder=""
                                validate={required}

                            ></Field>
                        </Col>


                    </Row>
                    <Row>
                        <Col lg="6">
                            {
                                props.lang == 'de' ?

                                    <Field
                                        name="Country"
                                        component={renderSelectField}
                                        label={props.translate('Država *')}
                                        placeholder=""
                                        validate={required}

                                    >
                                        {
                                            ['Avstrija', 'Litva', 'Belgija', 'Luksemburg', 'Bolgarija', 'Madžarska', 'Ciper', /*'Malta', 'Češka Republika',*/ 'Nemčija', 'Danska', 'Nizozemska', 'Estonija', 'Poljska', 'Finska', 'Portugalska', 'Francija', 'Romunija', 'Grčija', 'Slovaška', 'Hrvaška', 'Slovenija', 'Irska', 'Španija', 'Italija', 'Švedska', 'Latvija'].map((item, idx) => {
                                                return <option value={item}>{countriesMapDe[idx]}</option>
                                            })
                                        }

                                    </Field>

                                    :

                                    <Field
                                        name="Country"
                                        component={renderSelectField}
                                        label={props.translate('Država *')}
                                        placeholder=""
                                        validate={required}

                                    >
                                        <option>Avstrija</option>
                                        <option>Litva</option>
                                        <option>Belgija</option>
                                        <option>Luksemburg</option>
                                        <option>Bolgarija</option>
                                        <option>Madžarska</option>
                                        {/* <option>Ciper</option>
                                        <option>Malta</option> */}
                                        <option>Češka Republika</option>
                                        <option>Nemčija</option>
                                        <option>Danska</option>
                                        <option>Nizozemska</option>
                                        <option>Estonija</option>
                                        <option>Poljska</option>
                                        <option>Finska</option>
                                        <option>Portugalska</option>
                                        <option>Francija</option>
                                        <option>Romunija</option>
                                        <option>Grčija</option>
                                        <option>Slovaška</option>
                                        <option>Hrvaška</option>
                                        <option>Slovenija</option>
                                        <option>Irska</option>
                                        <option>Španija</option>
                                        <option>Italija</option>
                                        <option>Švedska</option>
                                        <option>Latvija</option>
                                    </Field>
                            }
                        </Col>
                        {/* {props.Country == 'Schweiz' ?
                            <Col lg="6">
                                <Field
                                    name="Region"
                                    component={renderSelectField}
                                    label={props.translate('Regija *')}
                                    placeholder=""
                                    validate={required}

                                >
                                    <option>Zürich</option>
                                    <option>Bern / Berne</option>
                                    <option>Luzern</option>
                                    <option>Uri</option>
                                    <option>Schwyz</option>
                                    <option>Unterwalden</option>
                                    <option>Glarus</option>
                                    <option>Zug</option>
                                    <option>Freiburg / Fribourg</option>
                                    <option>Solothurn</option>
                                    <option>Basel</option>
                                    <option>Schaffhausen</option>
                                    <option>Appenzell</option>
                                    <option>Sankt Gallen</option>
                                    <option>Graubünden</option>
                                    <option>Aargau</option>
                                    <option>Thurgau</option>
                                    <option>Ticino</option>
                                    <option>Vaud</option>
                                    <option>Valais / Wallis</option>
                                    <option>Neuchâtel</option>
                                    <option>Genève</option>
                                    <option>Jura</option>
                                </Field>
                            </Col>
                            :
                            null
                        } */}
                    </Row>
                    <Field
                        name="Phone"
                        type="text"
                        component={renderTextField}
                        label={props.translate('Telefon *')}
                        placeholder=""
                        validate={required}

                    ></Field>
                    <Field
                        name="EMail"
                        type="email"
                        component={renderTextField}
                        label={props.translate('Email *')}
                        placeholder=""
                        validate={required}

                    ></Field>

                    <Field
                        name="acceptTerms"
                        type="checkbox"
                        component={renderCheckField}
                        label={<>{props.translate('Pročitao sam i prihvatam ')} <Link to='/page/GTC' className="text-primary text-bold">{props.translate('Uslove i odredbe')}</Link></>}
                        placeholder=""
                        validate={required}

                    ></Field>


                </Col>
                {/*<Col lg="6">
                    <Field
                        name="Node"
                        type="text"
                        component={renderTextareaField}
                        label={props.translate('Napomena')}
                        placeholder=""
                    ></Field>

    </Col>*/}


            </Row>

            <p>{props.translate('* Obavezna polja')}</p>

            <button type="submit" className="button">{props.translate('NARUČI')}</button>


        </form>

    )
}

orderForm = reduxForm({
    form: 'orderForm'  // a unique identifier for this form
})(orderForm)

const selector = formValueSelector('orderForm');

orderForm = connect(state => {
    return {
        Country: selector(state, 'Country'),
    }


}, {
    ChangeRegion: value => change("orderForm", "Region", value),

})(orderForm)

export default orderForm;