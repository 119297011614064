import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';

import HomeHeader from '../containers/header/homeHeader';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import Categories from '../components/categories';

import star_icon from '../assets/svg/star-icon.svg';
import info_icon from '../assets/svg/info-icon.svg';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

import ContactForm from '../components/forms/contactForm';

class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.contact = this.contact.bind(this);

        this.state = {

        };

    }


    componentDidMount() {
        setTimeout(() => {
            if (typeof window !== 'undefined')
                window.scrollTo(0, 0);
        }, 300);
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });

        this.props.socketIOClient.on('contact', (data) => {
            console.log(data);
            this.setState({
                _done: true
            })
        });



    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        //this.props.socketIOClient.removeAllListeners("fetchProduct");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }


    contact(data) {
        console.log(data);

        this.props.socketIOClient.emit("contact", data);
    }




    render() {
        let product = this.state.product;

        return (
            <div className="account-wrap">

              

                <Container>
                    <Row>
                        <Col lg="9" className="content-wrap">
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <ul className="breadcrumb">
                                            <li><Link to='/'>{this.props.translate('Početna')}</Link></li>
                                            <li><Link to='/kontakt'>{this.props.translate('Kontakt')}</Link></li>

                                        </ul>
                                    </Col>
                                    <Col lg="5">
                                        <h1>{this.props.translate('Kontakt')}</h1>
                                    </Col>
                                    <Col lg="7">
                                    </Col>
                                    <Col lg="12">
                                        <Container className="box-container">
                                            <Row>

                                                <Col lg="12" >

                                                    { !this.state._done ?
                                                    <ContactForm translate={this.props.translate} onSubmit={this.contact}></ContactForm>
                                                    :
                                                        <p>{this.props.translate('Vaša poruka je poslata. Očekujte odgovor uskoro.')}</p>
                                                    }

                                                    
                                                </Col>

                                            </Row>
                                        </Container>



                                    </Col>

                                </Row>
                            </Container>

                        </Col>
                        <Col lg="3" className="d-none d-lg-block">
                            <Container>
                                <Row>
                                    {
                                        this.props.offerProducts.slice(Math.max(this.props.offerProducts.length - 3, 1)).map((item, idx) => {
                                            return (
                                                <Article addToCart={() => this.props.addToCart(item)} lang={this.props.lang}
                                                    _id={item._id}
                                                    slug={item.slug}
                                                    alias={item.Alias}
                                                    image={Object.translate(item, 'Images', this.props.lang ) ? Object.translate(item, 'Images', this.props.lang )[0]  : null}
                                                    title={item.Name}
                                                    package={item.package}
                                                    currency={this.props.currency}
                                                    discount={item.discount}
                                                    showDiscount={item.ShowDiscountPrice}
                                                    price={item.price ? item.price : 0}
                                                    inStock={item.StockLevel > 0 ? true : false}
                                                    translate={this.props.translate}>
                                                </Article>

                                            )
                                        })
                                    }
                                </Row>
                            </Container>                        </Col>

                    </Row>
                </Container>


                <Newsletter {...this.props} />


                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(ContactPage));
