import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,

} from 'reactstrap';



// import logo from '../assets/svg/logo.svg';
import logo from '../assets/svg/Rossen-logo-natural.svg';
import fb_icon from '../assets/svg/facebook-icon.svg';
import instagram_icon from '../assets/svg/instagram-icon.svg';
import tiktok_icon from '../assets/svg/tiktok_icon.svg';
import tiktok_logo from '../assets/svg/tiktok_logo.svg';

import phone_icon from '../assets/svg/phone-ico.svg';
import mail_icon from '../assets/svg/mail-ico.svg';
import instagram_logo from '../assets/svg/instagram-logo.svg';

import icon1 from '../assets/svg/1.svg';
import icon2 from '../assets/svg/2.svg';
import icon3 from '../assets/svg/3.svg';
import icon4 from '../assets/svg/4.svg';
import accepted from '../assets/svg/accepted.png';
import newsletterImage from '../assets/images/news-letter.jpg'
import newsletterImageMob from '../assets/images/news-letter-mob.jpg'

export class Footer extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.subscribe = this.subscribe.bind(this);

        this.state = {
            email: ''
        };
    }


    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });

        this.props.socketIOClient.on('subscribeToNewsletter', (data) => {
            console.log(data);
            this.setState({
                _done: true,
                email: ''
            })
        });



    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("subscribeToNewsletter");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }


    subscribe() {

        this.props.socketIOClient.emit("subscribeToNewsletter", { email: this.state.email });
    }


    render() {
        return (
            <>
                <section className='newsletter'>
                    <img src={newsletterImage}></img>
                    <img src={newsletterImageMob}></img>
                    <div className='mobile-overlay'></div>
                    <Container fluid>
                        <Row>
                            <Col lg="6" className="title">
                                <h2>
                                    {this.props.translate('Prvi saznajte za sve akcije i novosti koje Rossen sprema.')}
                                </h2>
                                <h6>
                                    {this.props.translate('Prijavite se na naš newsletter.')}
                                </h6>
                                {!this.state._done ?

                                    <div>
                                        <input type="text" value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }) }} placeholder={this.props.translate("Unesite Vašu E-mail adresu")} />
                                        <button className="button" onClick={this.subscribe}> {this.props.translate('PRIJAVI SE')}</button>




                                    </div>
                                    :
                                    <div>

                                        <p className="text-center">{this.props.translate('Uspješno ste se pretplati na naš newsletter.')}</p>



                                    </div>
                                }

                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section section-bottom">
                    <Container fluid>
                        <Row>
                            <Col lg="12" className="title">
                                <h2>
                                    {this.props.translate('PRATITE NAS')}   <span>{this.props.translate('NA INSTAGRAMU')}</span>
                                </h2>
                                <a href="https://instagram.com/rossen.natural.slo"><h6><Isvg src={instagram_logo} /> {this.props.translate('rossen.natural.slo')}</h6></a>
                            </Col>
                            <Col lg="12" className="title">
                                <h2>
                                    {this.props.translate('PRATITE NAS')}   <span>{this.props.translate('NA TIKTOKU')}</span>
                                </h2>
                                <a href="https://www.tiktok.com/@rossen.natural.slo?_t=8eYkCB1diSm&_r=1"><h6><Isvg src={tiktok_logo} /> {this.props.translate('rossen.natural.slo')}</h6></a>
                            </Col>


                            <Col lg="12" className="instagram-photos">
                                {
                                    this.state.instagramPhotos && this.state.instagramPhotos.map((item, idx) => {
                                        return (
                                            <a href={item.link}>
                                                <img src={item.preview} />
                                            </a>
                                        )
                                    })
                                }
                            </Col>
                        </Row>

                    </Container>

                    <Container>
                        <Row>
                            <Col lg="12" className="title">
                                <h2>
                                    {this.props.translate('BRINEMO')}  <span>{this.props.translate('O VAŠOJ LJEPOTI')}</span>
                                </h2>
                            </Col>

                            <Col lg="3" xs="6" className="item">
                                <Isvg src={icon1} />
                                <p>{this.props.translate('Bez sulfata')}</p>
                            </Col>
                            <Col lg="3" xs="6" className="item">
                                <Isvg src={icon2} />
                                <p>{this.props.translate('Bez parabena')}</p>
                            </Col>
                            <Col lg="3" xs="6" className="item">
                                <Isvg src={icon3} />
                                <p>{this.props.translate('Bez toksina')}</p>
                            </Col>
                            <Col lg="3" xs="6" className="item">
                                <Isvg src={icon4} />
                                <p>{this.props.translate('Etički kreirano')}</p>
                            </Col>

                        </Row>
                    </Container>

                </section>

                <footer className="footer">
                    <Container>

                        <Row>
                            <Col md="12" className="footer-content">
                                <Isvg src={logo} />
                                <p>{this.props.translate('Harmonija između čovjeka i prirode')}</p>
                                <div className="social">
                                    <a href={this.props.lang == 'de' ? "https://www.facebook.com/Rossen-Natural-De-109405174888459" : "https://www.facebook.com/Rossen-Natural-Slovenija-108409561479297/"} target="_blank"><Isvg src={fb_icon} /></a>
                                    <a href="https://www.instagram.com/rossen.natural.slo/" target="_blank"><Isvg src={instagram_icon} /></a>
                                    <a href="https://www.tiktok.com/@rossen.natural.slo?_t=8eYkCB1diSm&_r=1" target="_blank"><Isvg src={tiktok_icon} /></a>

                                </div>
                            </Col>
                            <div className="cards-accepted"><img src={accepted} /></div>
                            <div className="spacer"></div>
                            <ul className="footer-links">
                                {
                                    this.props.pages && Object.values(this.props.pages).map((item, idx) => {
                                        return <li>
                                            <Link to={`/page/${item.alias}`}>{Object.translate(item, 'title', this.props.lang)}</Link>
                                        </li>
                                    })
                                }
                            </ul>
                            <div className="spacer"></div>

                            <div className="copyright">
                                <p>{this.props.translate('Copyright © rossen-natural.si - 2020. All Rights Reserved.')}</p>
                            </div>

                        </Row>
                    </Container>

                </footer>
            </>
        )
    }
}

export default Footer;