import React, { Component } from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';


export class Loader extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    render() {
        if (!this.props.loading) {
            return null
        }
        let style = {}
        if (this.props.style) style = { ...this.props.style }
        if (this.props.backgroundColor) style.backgroundColor = this.props.backgroundColor;
        return (
            <div className='loader-wrap' style={style}>
                <Player
                    autoplay={true}
                    loop={true}
                    src="/rossen_loader.json"
                    style={{
                        height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "200px",
                        width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "200px",
                        position: 'absolute',
                        top: "50%",
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                ></Player>
            </div>
        )
    }
}

export default Loader;